$main-font: var(--SFPro), sans-serif
$primary-color: #001868
$c-purple: #5627FF
$c-lightPurple: #E0D8FF

$break375: 375px
$break30: 30rem
$break48: 48rem
$break62: 62rem
$break64: 64rem
$break75: 75rem
