@use 'common'
@import 'react-toastify/dist/ReactToastify.css'

:root
	--common-edge-gap: 1rem
	--common-content-width-small: 32rem
	--common-content-width-normal: 50rem
	--common-content-width-wide: 75rem
	--background-color: #FFFFFF
	--text-color: #001868
	overflow-y: scroll

	// https://www.npmjs.com/package/shared-loading-indicator
	--ProgressLoadingIndicator-color: #{common.$primary-color}

	@media (min-width: 40rem)
		--common-edge-gap: 2rem

	// @media (prefers-color-scheme: dark)
	// 	--background-color: #121212
	// 	--text-color: #FFFFFF

html
	scroll-behavior: smooth
body
	margin: 0
	font-family: common.$main-font
	background-color: var(--background-color)
	color: var(--text-color)

*, *::before, *::after
	box-sizing: border-box

button
	cursor: pointer

a
	color: common.$primary-color

h1
	font-size: 2.875rem
	font-weight: 700
	line-height: 3.125rem
	@media (min-width: common.$break48)
		font-size: 4.25rem
		line-height: 4.25rem

h2
	font-size: 2rem
	line-height: 2.1875rem
	@media (min-width: common.$break48)
		font-size: 3rem
		line-height: 3.25rem
